import { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import MainLayout from "../../../components/layouts/main";
import api, { createCancelToken } from "../../../services/api-client";
import ServiceResult from "../../../interfaces/service-result";
import CustomerModel from "../../../interfaces/models/customer.model";
import apiErrorHandler from "../../../services/api-error-handler";
import moment from "moment";
import { CustomerCashbackListItemModel } from "../../../interfaces/models/customer-cashback-list-item.model";
import { toast } from "react-toastify";
import { BuildRelativeUrl } from "../../../utils/build-relative-url";
import { TablePagination } from "../../../components/table-pagination";
import ListServiceResult from "../../../interfaces/list-service-result";
import clsx from "clsx";
import { ConvertToCurrency } from "../../../utils/convert-to-currency";

export default function DetalhesCarteira() {
  const cancelToken = createCancelToken();

  const { customerId } = useParams();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, _] = useSearchParams();
  const searchString = searchParams.get("search");
  const page = Number(searchParams.get("page")) || 1;
  const pageSize = Number(searchParams.get("pageSize")) || 25;

  const [customerData, setCustomerData] = useState<CustomerModel | null>(null);
  const [transactions, setTransactions] = useState<
    CustomerCashbackListItemModel[]
  >([]);
  const [balance, setBalance] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingTransactions, setLoadingTransactions] = useState<boolean>(true);

  const [numeroPaginas, setNumeroPaginas] = useState<number>(0);

  const fetchCustomerData = (id: string) => {
    setLoading(true);

    api
      .get<ServiceResult<CustomerModel>>(`/customers/${id}`, {
        cancelToken: cancelToken.token,
      })
      .then(({ data }) => {
        setCustomerData(data.result as CustomerModel);
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  };

  const fetchCustomerBalance = (id: string) => {
    api
      .get<ServiceResult<number>>(`/customers/${id}/balance`, {
        cancelToken: cancelToken.token,
      })
      .then(({ data }) => {
        setBalance(data.result || null);
      })
      .catch((error) => {
        toast.error("Erro ao carregar o saldo do cliente.");
        apiErrorHandler(error);
      });
  };

  const fetchTransactions = (id: string) => {
    setLoadingTransactions(true);

    api
      .get<ListServiceResult<CustomerCashbackListItemModel>>(
        BuildRelativeUrl(`/customers/${id}/cashbacks`, {
          page,
          pageSize,
        }),
        {
          cancelToken: cancelToken.token,
        },
      )
      .then(({ data }) => {
        setTransactions(data.result);
        setNumeroPaginas(data.pages);
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoadingTransactions(false));
  };

  useEffect(() => {
    if (customerId) {
      fetchCustomerBalance(customerId);
      fetchTransactions(customerId);
    }

    return () => {
      cancelToken.cancel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, page, pageSize, searchString]);

  // first load
  useEffect(() => {
    fetchCustomerData(customerId!);
    fetchCustomerBalance(customerId!);
    fetchTransactions(customerId!);

    return () => {
      cancelToken.cancel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      {!loading && (
        <>
          <p className="fs-4">Dados do cliente</p>
          <div className="p-1">
            <div className="row align-items-center">
              <div className="col-md-auto pr-md-0">
                <input
                  type="file"
                  className="d-none"
                  accept="image/*"
                  multiple={false}
                />
                <div
                  className="rounded-circle overflow-hidden cursor-pointer img-retail ratio ratio-1x1"
                  style={{ width: 105, height: 105 }}
                >
                  {customerData?.profileImage ? (
                    <img
                      src={customerData.profileImage}
                      alt="imagem de perfil da loja"
                      className="rounded-circle w-100 h-100"
                      style={{ objectFit: "cover" }}
                    />
                  ) : (
                    <div className="d-flex align-items-center justify-content-center">
                      <span>Sem imagem</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8">
              <div className="row mb-2 g-5">
                <div className="col-md-6">
                  <label
                    className="m-0 form-label mt-2 label-primary fw-bold"
                    htmlFor="fullName"
                  >
                    Nome completo
                  </label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    id="fullName"
                    value={customerData?.customerName || ""}
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <label
                    className="m-0 form-label mt-2 label-primary fw-bold"
                    htmlFor="nationalDocument"
                  >
                    Documento
                  </label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    id="nationalDocument"
                    value={customerData?.nationalDocument || ""}
                    disabled
                  />
                </div>
              </div>

              <div className="row mb-2 g-5">
                <div className="col-md-6">
                  <label
                    className="m-0 form-label mt-2 label-primary fw-bold"
                    htmlFor="phone"
                  >
                    Telefone
                  </label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    id="phone"
                    value={customerData?.phone || ""}
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <label
                    className="m-0 form-label mt-2 label-primary fw-bold"
                    htmlFor="birth"
                  >
                    Data de aniversário
                  </label>
                  <input
                    type="date"
                    className="form-control mt-2"
                    id="birth"
                    value={customerData?.birth || ""}
                    disabled
                  />
                </div>
              </div>

              <div className="row mb-2 g-5">
                <div className="col-md-6">
                  <label
                    className="m-0 form-label mt-2 label-primary fw-bold"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    id="email"
                    value={customerData?.email || ""}
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <label
                    className="m-0 form-label mt-2 label-primary fw-bold"
                    htmlFor="amount"
                  >
                    Saldo
                  </label>
                  <input
                    type="text"
                    className="form-control mt-2"
                    id="amount"
                    value={`R$ ${balance?.toFixed(2) || "0.00"}`}
                    disabled
                  />
                </div>
              </div>

              <hr />
            </div>

            <div className="col-md-12">
              <p className="fs-4 mb-4">Extrato</p>

              {loadingTransactions ? (
                <p>Carregando transações...</p>
              ) : transactions.length === 0 ? (
                <p>Nenhuma transação encontrada</p>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-changeback">
                      <thead className="table-light">
                        <tr>
                          <td>Valor</td>
                          <td>Data de Expiração</td>
                        </tr>
                      </thead>
                      <tbody className="align-middle">
                        {transactions.map((transaction) => (
                          <tr key={transaction.id}>
                            <td
                              className={clsx(
                                "fw-bold",
                                transaction.amount.toString().includes("-")
                                  ? "text-danger"
                                  : "text-success",
                              )}
                            >
                              {!transaction.amount.toString().includes("-") &&
                                "+"}
                              {ConvertToCurrency(transaction.amount)}
                            </td>
                            <td>
                              {moment(transaction.expirationDate).format(
                                "DD/MM/YYYY",
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <TablePagination numeroPaginas={numeroPaginas} />
                </>
              )}
            </div>
          </div>
        </>
      )}
    </MainLayout>
  );
}
