import MainLayout from "../../../components/layouts/main";
import { useEffect, useState } from "react";
import CompanyModel from "../../../interfaces/models/company.model";
import api, { createCancelToken } from "../../../services/api-client";
import ServiceResult from "../../../interfaces/service-result";
import { toast } from "react-toastify";
import apiErrorHandler from "../../../services/api-error-handler";
import { FormRules } from "./components/form-rules";
import { Levels } from "./components/levels";
import { FormCompany } from "./components/form-company";

export default function PartnersProfile() {
  const cancelToken = createCancelToken();

  const [loading, setLoading] = useState<boolean>(false);
  const [company, setCompany] = useState<CompanyModel | null>(null);
  const [instrucoes, setInstrucoes] = useState<boolean>(false);

  const getCompany = async () => {
    setLoading(true);

    api
      .get<ServiceResult<CompanyModel>>("/companies", {
        cancelToken: cancelToken.token,
      })
      .then(({ data }) => {
        setCompany(data.result as CompanyModel);
      })
      .catch((err) => {
        apiErrorHandler(err);
        toast.error("Erro ao buscar os dados");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCompany();

    return () => {
      cancelToken.cancel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      {loading ? (
        <p>Carregando...</p>
      ) : !company ? (
        <p className="alert alert-danger" role="alert">
          Ocorreu um erro ao buscar os dados da empresa
        </p>
      ) : (
        <div className="row">
          <div className="col-lg-9">
            <div className="accordion mb-2" id="accordionExample">
              <div className="accordion-item border-0 bg-transparent">
                <h2 className="accordion-header" id="heading_dados_basicos">
                  <button
                    className="accordion-button bg-transparent shadow-none p-2 fs-3"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse_dados_basicos"
                    aria-expanded="true"
                    aria-controls="collapse_dados_basicos"
                  >
                    Dados básicos
                  </button>
                </h2>
                <div
                  id="collapse_dados_basicos"
                  className="accordion-collapse collapse show"
                  aria-labelledby="heading_dados_basicos"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body p-2">
                    <FormCompany company={company} />
                  </div>
                </div>
              </div>

              <hr />

              <div className="accordion-item border-0 bg-transparent">
                <h2 className="accordion-header" id="heading_config_cashback">
                  <button
                    className="accordion-button bg-transparent shadow-none p-2 fs-3"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse_config_cashback"
                    aria-expanded="false"
                    aria-controls="collapse_config_cashback"
                  >
                    Configurações de cashback
                  </button>
                </h2>
                <div
                  id="collapse_config_cashback"
                  className="accordion-collapse collapse"
                  aria-labelledby="heading_config_cashback"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body p-2">
                    <FormRules initialRules={company?.cashbackRules} />

                    <div className="mt-5">
                      <label className="form-label label-primary fw-bold mt-4">
                        Progressão de desconto{" "}
                        <span
                          className="cursor-pointer text-primary fw-normal"
                          onClick={() => setInstrucoes(!instrucoes)}
                        >
                          ({instrucoes ? "Ocultar" : "Ver"} instruções)
                        </span>
                      </label>

                      {instrucoes && (
                        <div className="alert alert-info mt-2">
                          <p className="m-0">
                            Lorem ipsum dolor sit, amet consectetur adipisicing
                            elit. Suscipit porro esse ipsam dolor natus quod
                            voluptate eaque ipsum! Deleniti dolorem, eveniet
                            modi sunt sint possimus dolores iure nostrum earum
                            ipsam
                          </p>
                        </div>
                      )}

                      <Levels />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </MainLayout>
  );
}
