import { useEffect } from "react";
import MainLayout from "../../components/layouts/main";
import api, { createCancelToken } from "../../services/api-client";
import apiErrorHandler from "../../services/api-error-handler";
import { FormCashback } from "./components/form-cashback";
import ServiceResult from "../../interfaces/service-result";
import { useUserStore } from "../../stores/user.store";
import DashboardModel from "../../interfaces/models/dashboard.model";
import { AxiosError } from "axios";

export default function Dashboard() {
  const cancelToken = createCancelToken();

  const { companyName, setCompanyName, personFullName, setPersonFullName } =
    useUserStore();

  const getDashboard = async () =>
    api.get<ServiceResult<DashboardModel>>("/dashboard", {
      cancelToken: cancelToken.token,
    });

  const getInfos = async () => {
    try {
      const promises = [getDashboard()];
      const [resGetMe] = await Promise.all(promises);

      setCompanyName(resGetMe.data.result?.companyComercialName ?? "");
      setPersonFullName(resGetMe.data.result?.personFullName ?? "");
    } catch (err) {
      apiErrorHandler(err as Error | AxiosError<unknown, any>);
    }
  };

  function Greetings() {
    const agora = new Date();
    const hora = agora.getHours();

    if (hora >= 6 && hora < 12) {
      return "Bom dia";
    } else if (hora >= 12 && hora < 18) {
      return "Boa tarde";
    } else {
      return "Boa noite";
    }
  }

  useEffect(() => {
    getInfos();

    return () => {
      cancelToken.cancel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <div className="row g-0">
        <p className="fs-4 mb-4">
          {Greetings()}
          {personFullName ? `, ${personFullName}` : ""}
        </p>

        {companyName && (
          <p className="fs-4 mb-4">Estabelecimento: {companyName}</p>
        )}

        <FormCashback />
      </div>
    </MainLayout>
  );
}
