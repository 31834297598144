import { useForm } from "react-hook-form";
import EmptyModal from "../../../components/modals/EmptyModal";
import GrantType from "../../../enums/grant-type";
import AuthTokenPostArgs from "../../../interfaces/args/auth-token-post.args";
import { useNavigate } from "react-router-dom";
import { useLoginStore } from "../../../stores/login.store";
import { useState } from "react";
import api from "../../../services/api-client";
import { toast } from "react-toastify";
import apiErrorHandler from "../../../services/api-error-handler";
import ServiceResult from "../../../interfaces/service-result";
import AuthModel from "../../../interfaces/models/auth.model";
import clsx from "clsx";
import { SomenteNumeros } from "../../../utils/somente-numeros";

interface Props {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Confirmation = ({ openModal, setOpenModal }: Props) => {
  const navigate = useNavigate();

  const { user } = useLoginStore();

  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<AuthTokenPostArgs>({
    values: {
      grantType: GrantType.Passwordless,
      user: user,
    },
    shouldFocusError: false,
  });

  const handleLogin = async (args: AuthTokenPostArgs) => {
    setLoading(true);

    args.user = SomenteNumeros(args.user || "");

    api
      .postForm<ServiceResult<AuthModel>>("/auth/token", args)
      .then(({ data }) => {
        if (data.result) {
          sessionStorage.setItem("auth", JSON.stringify(data.result));
          navigate("/");
          toast.success("Bem vindo!", {
            position: "top-center",
          });
        }
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  };

  return (
    <EmptyModal
      isOpen={openModal}
      close={() => {
        setOpenModal(false);
        setValue("confirmationCode", "");
      }}
      title="Código de confirmação"
    >
      <form onSubmit={handleSubmit(handleLogin)}>
        <div className="alert alert-info">
          Enviamos um código de confirmação para o número: <br />{" "}
          <strong>{user}</strong>
        </div>

        <div className="input-group mt-2">
          <input
            className={clsx(
              "form-control form-control-lg",
              errors.confirmationCode ? "is-invalid" : ""
            )}
            type="number"
            id="confirmationCode"
            {...register("confirmationCode", {
              required: "Código de confirmação é obrigatório",
            })}
          />
        </div>
        {errors.confirmationCode?.message && (
          <p className="text-danger">{errors.confirmationCode.message}</p>
        )}
        <button
          disabled={loading}
          className="cb-btn-login mt-4 w-100 btn text-white"
          type="submit"
        >
          {loading ? "Aguarde..." : "Confirmar"}
        </button>
      </form>
    </EmptyModal>
  );
};
