import { Controller, useForm } from "react-hook-form";
import InputCurrency from "../../../../../components/form/input-currency";
import clsx from "clsx";
import { useEffect, useState } from "react";
import api from "../../../../../services/api-client";
import apiErrorHandler from "../../../../../services/api-error-handler";
import ListServiceResult from "../../../../../interfaces/list-service-result";
import { useSearchParams } from "react-router-dom";
import ServiceResult from "../../../../../interfaces/service-result";
import CashbackLevelPostArgs from "../../../../../interfaces/args/cashback-level-post.args";
import { SomenteNumeros } from "../../../../../utils/somente-numeros";
import { ConvertCurrencyToDecimal } from "../../../../../utils/convert-currency-to-decimal";
import CashbackLevelModel from "../../../../../interfaces/models/cashback-level.model";
import { BuildRelativeUrl } from "../../../../../utils/build-relative-url";
import { TablePagination } from "../../../../../components/table-pagination";
import TooltipButton from "../../../../../components/tooltip";
import { ConvertToCurrency } from "../../../../../utils/convert-to-currency";
import { HiOutlinePencilAlt, HiOutlineTrash } from "react-icons/hi";
import EmptyModal from "../../../../../components/modals/EmptyModal";
import { CurrencyInput } from "input-currency-react";
import { ModalEditLevel } from "./components/modal-edit-level";
import { useRefreshStore } from "../../../../../stores/refresh.store";

interface FormValues {
  de: string;
  ate: string;
  desconto: string;
}

export const Levels = () => {
  const { refreshCount } = useRefreshStore();

  const [searchParams] = useSearchParams();
  const page = Number(searchParams.get("page")) || 1;
  const pageSize = Number(searchParams.get("pageSize")) || 25;

  const [loadingGet, setLoadingGet] = useState<boolean>(true);
  const [loadingPost, setLoadingPost] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<string | null>(null); // string 'cause we use to disable the button in the list/modal

  const [levels, setLevels] = useState<CashbackLevelModel[]>([]);
  const [numeroPaginas, setNumeroPaginas] = useState<number>(0);

  const [auxLevel, setAuxLevel] = useState<CashbackLevelModel | null>(null);
  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);
  const [openModalConfirmDelete, setOpenModalConfirmDelete] =
    useState<boolean>(false);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    values: {
      de: "",
      ate: "",
      desconto: "",
    },
  });

  async function getLevels(): Promise<void> {
    setLoadingGet(true);

    api
      .get<ListServiceResult<CashbackLevelModel>>(
        BuildRelativeUrl(`/companies/cashback/levels`, {
          page,
          pageSize,
        })
      )
      .then(({ data }) => {
        setLevels(data.result);
        setNumeroPaginas(data.pages);
      })
      .catch((err) => {
        apiErrorHandler(err);
      })
      .finally(() => {
        setLoadingGet(false);
      });
  }

  async function handleCreateLevel(data: FormValues): Promise<void> {
    setLoadingPost(true);

    const args: CashbackLevelPostArgs = {
      discountPercentage: Number(SomenteNumeros(data.desconto)),
      maxApplicableValue: ConvertCurrencyToDecimal(data.ate) || null,
      minApplicableValue: ConvertCurrencyToDecimal(data.de),
    };

    api
      .post<ServiceResult>(`/companies/cashback/levels`, args)
      .then(() => {
        getLevels();
        reset();
      })
      .catch((err) => {
        apiErrorHandler(err);
      })
      .finally(() => {
        setLoadingPost(false);
      });
  }

  async function handleDeleteLevel(levelId: string): Promise<void> {
    setLoadingDelete(levelId);

    api
      .delete(`/companies/cashback/levels/${levelId}`)
      .then(() => {
        handleCloseModalConfirmDelete();
        getLevels();
      })
      .catch((err) => {
        apiErrorHandler(err);
      })
      .finally(() => {
        setLoadingDelete(null);
      });
  }

  function handleOpenModalEdit(level: CashbackLevelModel) {
    setAuxLevel(level);
    setOpenModalEdit(true);
  }

  function handleCloseModalEdit() {
    setOpenModalEdit(false);
    setAuxLevel(null);
  }

  function handleOpenModalConfirmDelete(level: CashbackLevelModel) {
    if (loadingDelete) return;
    setAuxLevel(level);
    setOpenModalConfirmDelete(true);
  }

  function handleCloseModalConfirmDelete() {
    setOpenModalConfirmDelete(false);
    setAuxLevel(null);
  }

  useEffect(() => {
    getLevels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, refreshCount]);

  return (
    <>
      <form onSubmit={handleSubmit(handleCreateLevel)} className="row g-3">
        <div className="col-xl-3">
          <label className="form-label" htmlFor="de">
            A partir de<span className="text-danger">*</span>
          </label>
          <InputCurrency
            control={control}
            name="de"
            validation={{ required: "Campo obrigatório" }}
            className={errors.de && "border-danger"}
          />
        </div>

        <div className="col-xl-3">
          <label className="form-label" htmlFor="ate">
            Até (opcional)
          </label>
          <Controller
            control={control}
            name="ate"
            rules={{}}
            render={({ field: { onChange, value } }) => (
              <div className="d-flex align-items-center justify-content-between">
                <CurrencyInput
                  className={clsx(
                    `form-control`,
                    errors && errors["ate"] && "is-invalid border-danger"
                  )}
                  value={value}
                  options={{
                    style: "currency",
                    allowNegative: false,
                    precision: 2,
                  }}
                  style={{ textAlign: "left" }}
                  onChangeEvent={(_, maskedValue) => {
                    onChange(maskedValue);
                  }}
                />
              </div>
            )}
          />
        </div>

        <div className="col-xl-3">
          <label className="form-label" htmlFor="desconto">
            Desconto<span className="text-danger">*</span>
          </label>
          <div className="position-relative">
            <input
              {...register("desconto", { required: "Campo obrigatório" })}
              type="text"
              className={clsx(
                "form-control pe-4",
                errors.desconto && "border-danger"
              )}
              style={{ direction: "rtl" }}
            />
            <span className="position-absolute me-2 end-0 top-50 translate-middle-y">
              %
            </span>
          </div>
        </div>

        <div className="col-xl-3 d-flex justify-content-end mt-3 mt-xl-0 align-items-xl-end justify-content-xl-start">
          <button
            className="btn btn-primary"
            type="submit"
            disabled={loadingPost}
          >
            {loadingPost ? "Adicionando..." : "Adicionar nível"}
          </button>
        </div>
      </form>

      {/* List */}
      {loadingGet ? (
        <div className="text-center mt-3">Carregando...</div>
      ) : levels.length <= 0 ? (
        <div className="text-center mt-3">
          Nenhum nível de cashback cadastrado até o momento.
        </div>
      ) : (
        <>
          <div className="table-responsive mt-4">
            <table className="table table-changeback">
              <thead className="table-light">
                <tr>
                  <th>A partir de</th>
                  <th>Até</th>
                  <th>Desconto</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {levels.map((level) => (
                  <tr key={level.id}>
                    <td className="fw-bold">
                      {ConvertToCurrency(level.minimumOrderAmount)}
                    </td>
                    <td className="fw-bold">
                      {level.maximumOrderAmount
                        ? ConvertToCurrency(level.maximumOrderAmount)
                        : "Sem limite"}
                    </td>
                    <td className="fw-bold">{level.discountPercentage}%</td>
                    <td data-icons>
                      <div className="d-flex align-items-center">
                        {/* EDIT */}
                        <div>
                          <HiOutlinePencilAlt
                            className="cursor-pointer"
                            size={20}
                            onClick={() => handleOpenModalEdit(level)}
                            data-tooltip-id={`edit-${level.id}`}
                            data-tooltip-variant="dark"
                          />
                          <TooltipButton
                            id={`edit-${level.id}`}
                            text="Editar"
                          />
                        </div>

                        {/* DELETE */}
                        <div>
                          <HiOutlineTrash
                            className="cursor-pointer"
                            size={20}
                            onClick={() => handleOpenModalConfirmDelete(level)}
                            data-tooltip-id={`delete-${level.id}`}
                            data-tooltip-variant="dark"
                          />
                          <TooltipButton
                            id={`delete-${level.id}`}
                            text="Excluir"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <TablePagination numeroPaginas={numeroPaginas} />
        </>
      )}

      {/* Modal de confirmação de exlusão */}
      <EmptyModal
        close={handleCloseModalConfirmDelete}
        isOpen={openModalConfirmDelete}
        title="Confirmar exclusão de nível de desconto?"
      >
        <div className="d-flex align-items-center">
          <button
            className="btn btn-danger w-100"
            onClick={handleCloseModalConfirmDelete}
          >
            Cancelar
          </button>
          <button
            className="btn btn-success ms-2 w-100"
            onClick={() => handleDeleteLevel(auxLevel?.id || "")}
            disabled={loadingDelete === auxLevel?.id}
          >
            {loadingDelete ? "Excluindo..." : "Excluir"}
          </button>
        </div>
      </EmptyModal>

      {/* Modal de edição */}
      <ModalEditLevel
        isOpen={openModalEdit}
        onClose={handleCloseModalEdit}
        level={auxLevel!}
      />
    </>
  );
};
