import { useForm } from "react-hook-form";
import { FaSearch } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";

interface Props {
  queryParamName?: string;
}

export const SearchFilter = ({ queryParamName }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchString = searchParams.get("search");

  const { register, handleSubmit } = useForm<{ search: string }>({
    values: {
      search: searchString || "",
    },
  });

  const onSubmit = (data: { search: string }) => {
    setSearchParams({ [queryParamName || "search"]: data.search });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="d-flex">
      <div className="input-group position-relative">
        <button type="submit" className="btn border">
          <FaSearch />
        </button>
        <input
          type="text"
          className="w-100 form-control border shadow-none"
          placeholder="Pesquisar"
          style={{ maxWidth: "200px", paddingRight: 30 }}
          {...register("search")}
        />
        {searchString && (
          <button
            type="button"
            className="btn-close position-absolute me-1 top-50 end-0 translate-middle-y rounded-0"
            style={{ zIndex: 5 }}
            onClick={() => setSearchParams({})}
          />
        )}
      </div>
    </form>
  );
};
