import { useSearchParams } from "react-router-dom";
import MainLayout from "../../components/layouts/main";
import { useEffect, useState } from "react";
import api, { createCancelToken } from "../../services/api-client";
import ListServiceResult from "../../interfaces/list-service-result";
import { BuildRelativeUrl } from "../../utils/build-relative-url";
import apiErrorHandler from "../../services/api-error-handler";
import { TablePagination } from "../../components/table-pagination";
import { CustomerListItemModel } from "../../interfaces/models/customer-list-item.model";
import { TableCustomer } from "./components/table-customer";
import { useRefreshStore } from "../../stores/refresh.store";
import { SearchFilter } from "../../components/search-filter";

export default function Customers() {
  const cancelToken = createCancelToken();

  const [searchParams] = useSearchParams();
  const searchString = searchParams.get("search");
  const page = Number(searchParams.get("page")) || 1;
  const pageSize = Number(searchParams.get("pageSize")) || 25;

  const [customers, setCustomers] = useState<CustomerListItemModel[]>([]);
  const [numeroPaginas, setNumeroPaginas] = useState<number>(0);

  const { setRefreshing, refreshCount } = useRefreshStore();

  const getCustomers = async (): Promise<void> => {
    setRefreshing(true);

    api
      .get<ListServiceResult<CustomerListItemModel>>(
        BuildRelativeUrl("/customers", {
          page,
          pageSize,
          filter: searchString,
        }),
        {
          cancelToken: cancelToken.token,
        }
      )
      .then(({ data }) => {
        setCustomers(data.result);
        setNumeroPaginas(data.pages);
      })
      .catch((err) => {
        apiErrorHandler(err);
      })
      .finally(() => setRefreshing(false));
  };

  useEffect(() => {
    getCustomers();

    return () => {
      cancelToken.cancel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, searchString, refreshCount]);

  return (
    <MainLayout>
      <div className="row g-0">
        <div className="d-flex flex-row justify-content-between align-items-center flex-wrap mb-3">
          <h2 className="fs-4">Registro de clientes</h2>

          <SearchFilter />
        </div>

        <TableCustomer customers={customers} />

        <div className="col-xl-9">
          <TablePagination numeroPaginas={numeroPaginas} />
        </div>
      </div>
    </MainLayout>
  );
}
