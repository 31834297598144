import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CleanLayout from "../../components/layouts/clean";
import AuthTokenPostArgs from "../../interfaces/args/auth-token-post.args";
import GrantType from "../../enums/grant-type";
import api from "../../services/api-client";
import ServiceResult from "../../interfaces/service-result";
import apiErrorHandler from "../../services/api-error-handler";
import PhoneInput from "react-phone-input-2";
import AuthModel from "../../interfaces/models/auth.model";
import { Confirmation } from "./components/confirmation";
import { useLoginStore } from "../../stores/login.store";
import { SomenteNumeros } from "../../utils/somente-numeros";

export default function Login() {
  const { user, setUser } = useLoginStore();

  const [loading, setLoading] = useState<boolean>(false);

  const [openModal, setOpenModal] = useState<boolean>(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<AuthTokenPostArgs>({
    values: {
      grantType: GrantType.Passwordless,
      user: user,
    },
    shouldFocusError: false,
  });

  const sendConfirmationCode = async (args: AuthTokenPostArgs) => {
    setLoading(true);

    setUser(args.user || "");

    args.user = SomenteNumeros(args.user || "");

    api
      .postForm<ServiceResult<AuthModel>>("/auth/token", args)
      .then(({ data }) => {
        if (!data.result) {
          setOpenModal(true);
        }
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  };

  return (
    <CleanLayout>
      <form
        className="w-100 d-flex flex-column justify-content-between"
        onSubmit={handleSubmit(sendConfirmationCode)}
      >
        <div className="mb-3">
          <label className="m-0 form-label mt-2 fw-bold" htmlFor="email">
            Telefone
          </label>
          <Controller
            name="user"
            control={control}
            rules={{ required: "O número de telefone é obrigatório" }}
            render={({ field }) => (
              <div>
                <PhoneInput
                  containerClass="react-tel-input"
                  inputClass="form-control"
                  {...field}
                  country={"br"}
                  enableAreaCodes
                  enableSearch
                  placeholder="Celular"
                  inputStyle={{
                    width: "100%",
                    height: "37px",
                    borderColor: "#e4e2e2",
                  }}
                  inputProps={{
                    autoFocus: false,
                    name: field.name,
                    ref: field.ref,
                  }}
                  onChange={(value, country, event, formattedValue) =>
                    field.onChange(formattedValue)
                  }
                />

                {errors.user && (
                  <p className="text-danger">{errors.user.message}</p>
                )}
              </div>
            )}
          />
        </div>

        <div className="d-flex flex-column justify-content-center align-items-center">
          <button
            disabled={loading}
            className="cb-btn-login mb-4 w-100 btn text-white"
            type="submit"
          >
            {loading ? "Aguarde..." : "Acessar"}
          </button>
        </div>
      </form>

      <Confirmation openModal={openModal} setOpenModal={setOpenModal} />
    </CleanLayout>
  );
}
