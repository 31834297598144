import moment from "moment";
import "moment/locale/pt-br";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import MainLayout from "../../components/layouts/main";
import ServiceResult from "../../interfaces/service-result";
import api, { createCancelToken } from "../../services/api-client";
import apiErrorHandler from "../../services/api-error-handler";
import { BuildRelativeUrl } from "../../utils/build-relative-url";
import { DateFilter } from "./components/date-filter";
import { LineChart } from "./components/line-chart";
import { PieChart } from "./components/pie-chart";
import SummaryStatisticModel from "../../interfaces/models/summary-statistic.model";
import DetailedStatisticModel from "../../interfaces/models/detailed-statistic.model";

export default function Insights() {
  const cancelToken = createCancelToken();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, _] = useSearchParams();
  const dataInicial =
    searchParams.get("dataInicial") ||
    moment().add(-14, "day").format("YYYY-MM-DD");
  const dataFinal =
    searchParams.get("dataFinal") || moment().format("YYYY-MM-DD");

  const [loading, setLoading] = useState<boolean>(false);
  const [summaryStatisticsCount, setSummaryStatisticsCount] = useState<
    SummaryStatisticModel[]
  >([]);
  const [detailedStatisticsCount, setDetailedStatisticsCount] = useState<
    DetailedStatisticModel[]
  >([]);

  const [summaryStatisticsAmount, setSummaryStatisticsAmount] = useState<
    SummaryStatisticModel[]
  >([]);
  const [detailedStatisticsAmount, setDetailedStatisticsAmount] = useState<
    DetailedStatisticModel[]
  >([]);

  const getSummaryCount = async (type: "count" | "amount") => {
    setLoading(true);

    api
      .get<ServiceResult<SummaryStatisticModel[]>>(
        BuildRelativeUrl(`/insights/summary?type=${type}`, {
          dateStart: dataInicial,
          dateEnd: dataFinal,
        }), {
          cancelToken: cancelToken.token,
        }
      )
      .then(({ data }) => {
        if (type === "count") {
          setSummaryStatisticsCount(data.result ?? []);
        } else {
          setSummaryStatisticsAmount(data.result ?? []);
        }
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  };

  const getDetailedCount = async (type: "count" | "amount") => {
    setLoading(true);

    api
      .get<ServiceResult<DetailedStatisticModel[]>>(
        BuildRelativeUrl(`/insights/detailed?type=${type}`, {
          dateStart: dataInicial,
          dateEnd: dataFinal,
        }), {
          cancelToken: cancelToken.token,
        }
      )
      .then(({ data }) => {
        if (type === "count") {
          setDetailedStatisticsCount(data.result ?? []);
        } else {
          setDetailedStatisticsAmount(data.result ?? []);
        }
      })
      .catch((err) => apiErrorHandler(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getSummaryCount("count");
    getSummaryCount("amount");
    getDetailedCount("count");
    getDetailedCount("amount");

    return () => {
      cancelToken.cancel();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataInicial, dataFinal]);

  return (
    <MainLayout>
      <div className="row g-0 d-flex flex-column justify-content-center align-items-center">
        <div className="col-12">
          <DateFilter />
        </div>

        {loading && <p>Carregando...</p>}

        {!loading &&
          (!(
            summaryStatisticsCount.length > 0 ||
            detailedStatisticsCount.length > 0 ||
            summaryStatisticsAmount.length > 0 ||
            detailedStatisticsAmount.length > 0
          ) ? (
            <div className="alert alert-warning" role="alert">
              Não há dados suficientes para gerar o gráfico do dia{" "}
              {moment(dataInicial).format("DD/MM/YYYY")} até{" "}
              {moment(dataFinal).format("DD/MM/YYYY")}
            </div>
          ) : (
            <>
              <div className="row g-4">
                <div className="col-12 col-xl-4 d-flex justify-content-center justify-content-xl-start">
                  <div
                    className="rounded-3"
                    style={{
                      boxShadow: "2px 3px 3px rgba(0, 0, 0, 0.15)",
                      backgroundColor: "#FAFAFA",
                      width: "100%",
                      maxWidth: "380px",
                      height: "530px",
                    }}
                  >
                    <div
                      className="d-flex align-items-center justify-content-between p-2"
                      style={{
                        height: "62px",
                        backgroundColor: "#F2F2F2",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    >
                      <p className="mb-0 mx-3 semibold-16">
                        Total de transações
                      </p>
                      <img
                        className="me-3"
                        src="/images/icon.png"
                        alt="Ícone"
                      />
                    </div>
                    <div className="mt-5" style={{ height: "350px" }}>
                      <PieChart data={summaryStatisticsCount} />
                    </div>
                  </div>
                </div>

                <div className="col-12 col-xl-8 d-flex justify-content-center">
                  <div className="h-100 w-100" style={{ minHeight: "400px" }}>
                    <LineChart
                      data={detailedStatisticsCount}
                      startDate={moment(dataInicial)}
                      endDate={moment(dataFinal)}
                      title="Total de transações"
                      type="count"
                    />
                  </div>
                </div>
              </div>

              <div className="row g-4 mt-4 ">
                <div className="col-12 col-xl-4 d-flex justify-content-center justify-content-xl-start">
                  <div
                    className="rounded-3"
                    style={{
                      boxShadow: "2px 3px 3px rgba(0, 0, 0, 0.15)",
                      backgroundColor: "#FAFAFA",
                      width: "100%",
                      maxWidth: "380px",
                      height: "530px",
                    }}
                  >
                    <div
                      className="d-flex align-items-center justify-content-between p-2"
                      style={{
                        height: "62px",
                        backgroundColor: "#F2F2F2",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    >
                      <p className="mb-0 mx-3 semibold-16">
                        Total transacionado
                      </p>
                      <img
                        className="me-3"
                        src="/images/icon.png"
                        alt="Ícone"
                      />
                    </div>
                    <div className="mt-5" style={{ height: "350px" }}>
                      <PieChart data={summaryStatisticsAmount} />
                    </div>
                  </div>
                </div>

                <div className="col-12 col-xl-8 d-flex justify-content-center">
                  <div className="h-100 w-100" style={{ minHeight: "400px" }}>
                    <LineChart
                      data={detailedStatisticsAmount}
                      startDate={moment(dataInicial)}
                      endDate={moment(dataFinal)}
                      title="Total transacionado"
                      type="amount"
                    />
                  </div>
                </div>
              </div>
            </>
          ))}
      </div>
    </MainLayout>
  );
}
