import { useState } from "react";
import EmptyModal from "../../../../../../components/modals/EmptyModal";
import api from "../../../../../../services/api-client";
import CashbackLevelModel from "../../../../../../interfaces/models/cashback-level.model";
import CashbackLevelPostArgs from "../../../../../../interfaces/args/cashback-level-post.args";
import { useRefreshStore } from "../../../../../../stores/refresh.store";
import apiErrorHandler from "../../../../../../services/api-error-handler";
import { Controller, useForm } from "react-hook-form";
import { SomenteNumeros } from "../../../../../../utils/somente-numeros";
import { ConvertCurrencyToDecimal } from "../../../../../../utils/convert-currency-to-decimal";
import InputCurrency from "../../../../../../components/form/input-currency";
import { CurrencyInput } from "input-currency-react";
import clsx from "clsx";
import { toast } from "react-toastify";
import { ConvertToCurrency } from "../../../../../../utils/convert-to-currency";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  level: CashbackLevelModel;
}

interface FormValues {
  de: string;
  ate: string;
  desconto: string;
}

export const ModalEditLevel = ({ isOpen, onClose, level }: Props) => {
  const { refresh } = useRefreshStore();

  const [loadingPut, setLoadingPut] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    values: {
      de: ConvertToCurrency(level?.minimumOrderAmount),
      ate: ConvertToCurrency(level?.maximumOrderAmount),
      desconto: level?.discountPercentage.toString(),
    },
  });

  async function handleEditLevel(data: FormValues): Promise<void> {
    setLoadingPut(true);

    const args: CashbackLevelPostArgs = {
      discountPercentage: Number(SomenteNumeros(data.desconto)),
      maxApplicableValue: ConvertCurrencyToDecimal(data.ate) || null,
      minApplicableValue: ConvertCurrencyToDecimal(data.de),
    };

    api
      .put(`/companies/cashback/levels/${level.id}`, args)
      .then(() => {
        onClose();
        refresh();
        toast.success("Nível de desconto editado com sucesso");
      })
      .catch((err) => {
        apiErrorHandler(err);
      })
      .finally(() => {
        setLoadingPut(false);
      });
  }

  return (
    <EmptyModal
      close={onClose}
      isOpen={isOpen}
      title="Editar nível de desconto"
    >
      <form onSubmit={handleSubmit(handleEditLevel)} className="row g-3">
        <div className="col-xl-12">
          <label className="form-label" htmlFor="de">
            A partir de<span className="text-danger">*</span>
          </label>
          <InputCurrency
            control={control}
            name="de"
            validation={{ required: "Campo obrigatório" }}
            className={errors.de && "border-danger"}
          />
        </div>

        <div className="col-xl-12">
          <label className="form-label" htmlFor="ate">
            Até (opcional)
          </label>
          <Controller
            control={control}
            name="ate"
            rules={{}}
            render={({ field: { onChange, value } }) => (
              <div className="d-flex align-items-center justify-content-between">
                <CurrencyInput
                  className={clsx(
                    `form-control`,
                    errors && errors["ate"] && "is-invalid border-danger"
                  )}
                  value={value}
                  options={{
                    style: "currency",
                    allowNegative: false,
                    precision: 2,
                  }}
                  style={{ textAlign: "left" }}
                  onChangeEvent={(_, maskedValue) => {
                    onChange(maskedValue);
                  }}
                />
              </div>
            )}
          />
        </div>

        <div className="col-xl-3">
          <label className="form-label" htmlFor="desconto">
            Desconto<span className="text-danger">*</span>
          </label>
          <div className="position-relative">
            <input
              {...register("desconto", { required: "Campo obrigatório" })}
              type="text"
              className={clsx(
                "form-control pe-4",
                errors.desconto && "border-danger"
              )}
              style={{ direction: "rtl" }}
            />
            <span className="position-absolute me-2 end-0 top-50 translate-middle-y">
              %
            </span>
          </div>
        </div>

        <div className="col-xl-3 d-flex justify-content-end mt-3 mt-xl-0 align-items-xl-end justify-content-xl-start">
          <button className="btn btn-secondary" onClick={onClose}>
            Cancelar
          </button>

          <button
            className="btn btn-primary ms-2"
            type="submit"
            disabled={loadingPut}
          >
            {loadingPut ? "Salvando..." : "Salvar"}
          </button>
        </div>
      </form>
    </EmptyModal>
  );
};
