import { create } from "zustand";

type LoginStore = {
  user: string;
  setUser: (user: string) => void;
};

export const useLoginStore = create<LoginStore>()((set) => ({
  user: "",
  setUser: (user: string) => set({ user }),
}));
