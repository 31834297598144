import { useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../../../services/api-client";
import apiErrorHandler, {
  getApiErrorMessage,
} from "../../../../services/api-error-handler";
import { toast } from "react-toastify";

interface Props {
  initialRules: string | null;
}

interface FormValues {
  rules: string;
}

export const FormRules = ({ initialRules }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormValues>({
    values: {
      rules: initialRules || "",
    },
  });

  async function onSubmit(data: FormValues): Promise<void> {
    setLoading(true);

    toast
      .promise(
        api.patch(`/companies/cashback/rules`, {
          cashbackRules: data.rules,
        }),
        {
          error: {
            render({ data }) {
              return getApiErrorMessage(data);
            },
          },
          pending: "Salvando...",
          success: {
            render() {
              return "Regras salva com sucesso!";
            },
          },
        }
      )
      .then(() => {})
      .catch((err) => {
        apiErrorHandler(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-2">
        <label htmlFor="regras" className="form-label label-primary fw-bold">
          Regras do programa de cashback
        </label>
        <textarea
          className="form-control"
          id="regras"
          rows={3}
          {...register("rules", { required: "Campo obrigatório" })}
        />
        {errors.rules && (
          <span className="text-danger">{errors.rules.message}</span>
        )}
      </div>

      <button
        type="submit"
        className="mt-2 btn btn-primary float-end"
        disabled={loading}
      >
        {loading ? "Aguarde..." : "Salvar"}
      </button>
    </form>
  );
};
