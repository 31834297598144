import { CgProfile } from "react-icons/cg";
import { getCurrentPageName } from "../../routes";
import { useUserStore } from "../../stores/user.store";
import { Link, useNavigate } from "react-router-dom";
import api from "../../services/api-client";
import ServiceResult from "../../interfaces/service-result";
import MeModel from "../../interfaces/models/me.model";
import { useEffect } from "react";
import { getCurrentEnvImage } from "../../utils/get-current-env";
import { getCurrentEnvText } from "../../utils/get-current-text-env";

export default function Header() {
  const navigate = useNavigate();

  const { image, setImage, personFullName } = useUserStore();

  async function fetchImageProfile(): Promise<void> {
    api.get<ServiceResult<MeModel>>("/me").then(({ data }) => {
      setImage(data.result?.profileImage ?? "");
    });
  }

  function getUserNameAcronym(): string {
    if (!personFullName) {
      return "";
    }

    const fullNameUpperCase = personFullName.trim().toUpperCase();

    const names = fullNameUpperCase.split(" ");

    if (names.length === 1) {
      return names[0][0];
    }

    const firstLetter = names[0][0];
    const lastLetter = names[names.length - 1][0];

    return `${firstLetter}${lastLetter}`;
  }

  useEffect(() => {
    fetchImageProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="d-flex align-items-center justify-content-between p-2 p-md-3 header">
      <div className="d-flex align-items-center">
        {getCurrentEnvImage() && (
          <div className="d-flex flex-column align-items-center opacity-50 me-3">
            <img
              src={getCurrentEnvImage()}
              alt="Imagem de fundo"
              className="object-cover"
              style={{ height: "40px", width: "40px" }}
            />
            <span className="text-black">{getCurrentEnvText()}</span>
          </div>
        )}

        <h1 className="m-0">{getCurrentPageName()}</h1>
      </div>

      <div className="d-md-flex d-none align-items-center">
        <button
          className="me-2 btn btn-link text-decoration-none color-secondary"
          onClick={() => {
            sessionStorage.clear();
            navigate("/login");
          }}
        >
          Sair
        </button>

        <Link
          to="/personal-profile"
          className={`text-decoration-none ${
            image ? "ratio ratio-1x1 rounded-circle shadow-sm" : ""
          }`}
          style={{
            border: image ? "2px solid #2550A0" : "",
            width: image ? "54px" : "45px",
          }}
        >
          {image ? (
            <img
              src={image}
              alt="imagem de perfil"
              className="rounded-circle w-100 h-100"
              style={{ objectFit: "cover" }}
            />
          ) : personFullName ? (
            <span
              className="fs-4 text-primary border border-primary rounded-circle d-flex align-items-center justify-content-center"
              style={{
                height: 50,
                width: 50,
              }}
            >
              {getUserNameAcronym()}
            </span>
          ) : (
            <CgProfile color="#143166" size="2.3rem" />
          )}
        </Link>
      </div>
    </div>
  );
}
